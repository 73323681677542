import * as React from 'react'
const Block = (props: any) => {
  const ratio = props.ratio ? props.ratio : '3x2'
  return (
    <>
      <section  className={'tpl-block ' + props.className}>
        {props.children}
      </section>
    </>
  )
}

export default Block
