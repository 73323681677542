import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
const HeaderWrap = styled.div`
    & + section {
        padding-top: 0;
    }
    & + .icons-link-block, & + .list-trainner-box {
        padding-top: 2rem ;
    }
    margin-bottom: 2rem;
`;
const Header = styled.h4`
    margin: 0;
    border-bottom: 2px solid rgba(0,0,0,0.1);
    padding: 4.5rem 0 3rem 0;
    flex: 1
`;
const CTA = styled.div`
    margin: 0;
    border-bottom: 1px solid;
    padding: 3.5rem;
`;
const Text = styled.div`
    max-width: 820px;
    a {
        text-decoration: none
    }
    p:last-child {
        margin-bottom: 0;
    }
`;
const Right = styled.div`
  font-weight: normal;
`;
const Heading = (props: any) => {
    const data = props.data
    const link = props.link
    const className = props.className ?  props.className : ''
  return (
    <>
        <HeaderWrap className={'heading-block ' + props.background + ' ' + className }  id='app-header' >
            <div className='container'>
                <Header className='d-md-flex justify-content-between align-items-center'>
                    <div className='pe-4'> {props.title}</div>
                    {
                        link ? (
                            <div className='link'>
                                <Link to={link.link} className='link-more link-more-lg'>{link.text} <span>➜</span></Link>
                            </div>
                        ) : null
                    }
                    {
                    props.textRight ? (
                        <Right className='f-16 text-muted ps-md-3 mt-3 mt-md-0' dangerouslySetInnerHTML={{__html: props.textRight}} />
                    ) : null
                }
                </Header>
                {
                    props.text ? (
                        <Text className='text mt-4 pt-2' dangerouslySetInnerHTML={{__html: props.text}}/>
                    ) : null
                }
            </div> 
        </HeaderWrap> 
    </>
  )
}

export default Heading
