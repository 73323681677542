export const primary = '#0A499F';
export const yellow = '#FED70A';
export const blue = '#0A499F';
export const red = '#E8171C';
// $blue:    #0A499F !default;
// $indigo:  #6610f2 !default;
// $purple:  #6f42c1 !default;
// $pink:    #d63384 !default;

// $orange:  #E2734A !default;
// $yellow:  #FED70A !default;
// $green:   #198754 !default;
// $teal:    #20c997 !default;
// $cyan:    #0dcaf0 !default;
// $light-green: #009623;