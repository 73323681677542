import * as React from 'react'

const SearchLocation = (props: any) => {
  const className = props.className ?  props.className : ''
  let [searchValue, setSearchValue] = React.useState('')
  const handleSearch = (event: any) => {
    setSearchValue(event);
  }
  return (
    <>
       <div className={'search-location ' + className}>
          <div className={searchValue ? 'open-search container' : 'container'}>
            <form className={'d-block search-location-form position-relative'} action='/search-location-result'>
                <div className='position-relative'>
                <i className='icon-search'></i>
                <input type="text" 
                  className={searchValue ? 'searching form-control rounder': 'form-control rounder' }
                  value={searchValue} 
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder='Search postcode or suburb' />
                 <i className='icon-close' onClick={() => setSearchValue('')}></i>
                <div className='autocomplete'>
                  <div className='autocomplete-dropdown'>
                    <div className='d-flex  autocomplete-item'>
                      <div className='icon'>
                        <img src='/images/flag.svg' />
                      </div>
                      <div className='text'>
                        <div className='name'>Sydney North</div>
                        <div className='des'>Outdoor camp</div>
                      </div>
                    </div>
                    <div className='d-flex  autocomplete-item'>
                      <div className='icon'>
                        <img src='/images/location.svg' />
                      </div>
                      <div className='text'>
                        <div className='name'>Sydney</div>
                        <div className='des'>New South Wales, Australia</div>
                      </div>
                    </div>
                    <div className='d-flex  autocomplete-item'>
                      <div className='icon'>
                      <img src='/images/location.svg' />
                      </div>
                      <div className='text'>
                        <div className='name'>Sydney Olympic Park</div>
                        <div className='des'>New South Wales, Australia</div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className='overlay'></div>
            </form>
          </div>
       </div>
    </>
  )
}

export default SearchLocation
