import * as React from 'react'
import { Link } from 'gatsby'
import CardImage from './common/card-image'
import Block from './common/block'
const FourthCard = (props: any) => {
  const data = props.data.cards;
  const HalfColMobile = props.halfColMobile ? ' half-col-mobile ' : ''
  const NoSpacingBottom = props.noSpacingBottom ? ' pb-0 ' : ''
  const NoLink = props.noLink ? 'no-link card-title'  : 'card-title'
  const Vertical = props.vertical ? 'col-lg-12' : 'col-lg-3 col-md-6'
  
  const className = props.className ?  props.className : ''
  return (
    <>
      <Block className={'list-card-block '+ props.background + ' ' + className + HalfColMobile + NoSpacingBottom}>
        <div className='container'>
          <div className='row '>
              {
                data.map((card: any)=>(
                  <div className={Vertical} key={card.id}>
                    <Link to={card.link ? card.link : ''}>
                       <CardImage src={card.image}  />
                    </Link>
                    {
                    card.title ? (
                      <Link className={NoLink } to={card.link ? card.link : ''}>{card.title} <span>➜</span></Link> 
                      ) : null
                    }
                    {
                    card.text ? (
                      <div className='card-text f-16 mt-3'>{card.text}</div> 
                      ) : null
                    }
                  </div>
                ))
              }
              
          </div>
        </div>
      </Block>
      
    </>
  )
}

export default FourthCard
