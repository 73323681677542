import * as React from "react"
import Layout from "../components/common/layout"
import SearchBar from "../components/common/search-bar"
import Heading from "../components/common/heading" 
import {  graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import FourthCard from "../components/fourth-card"
// markup 
const locations = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const dataTitle = _data.find((item: any) => item.block === 'title')
  const hero = _data.find((item: any) => item.block === 'hero')
  const fourthCard = _data.find((item: any) => item.block === 'fourth-card')
  const link1 = {
    "link": "/video-hub",
    "text": "Video hub",
  }
  const link2 = {
    "link": "/",
    "text": "Find out more",
  }

  return (
    <Layout>
      <Helmet
        title='Search Location'
      />
      <SearchBar />
      <Heading 
        text='4 results near Campbelltown, New South Wales, Australia'
        title='Outdoor classes' 
        background='bg-white'/>
      <FourthCard data={fourthCard.data[0]} background="bg-white" halfColMobile={true} noSpacingBottom={true}></FourthCard>
      <Heading 
       link = {link1}
        title='On-demand classes' 
        background='bg-white'  
        text='Our <a href="">video hub</a> contains quick bursts of inspiration, guidance and motivation to help boost your fitness levels. We also have lots of ideas to improve you eating habits and calm your mind. New content is released every week.'
      />
      <FourthCard data={fourthCard.data[1]} background="bg-white" halfColMobile={true} noSpacingBottom={true}></FourthCard>
      <Heading 
        link = {link2}
        title='Daily live-stream classes'
        text='Our live-stream classes run multiple times per day, we offer cardio, cross-training, boxing, yoga, meditation, pilates and tai chi.'
        background='bg-white'/>
      <FourthCard data={fourthCard.data[2]} background="bg-white" halfColMobile={true}></FourthCard>
     
    </Layout> 
  )
}

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["locations-search-result"]}}) {
      nodes {
        jsonName
        data {
          block
          data {
            hero {
              hight_light
              content
              images {
                id
                url
                width
              }
              title
              title_top
            }
            title_data {
              title
              content
            }
            
            cards {
              id
              title
              image
              link
            }
          }
        }
      }
    }
  }
`
export default locations
