import * as React from 'react'
import styled from 'styled-components'
import SearchLocation from "./search-location"
import { yellow } from '../../design/colors/brand'
import { breakpointNotDesktop} from '../../design/responsive/breakpoint'
const SubNavTitle = styled.div`
    color: ${yellow};
    font-weight: bold;
    padding-right: 4.3rem;
    line-height: 2rem;
    border-right: 2px solid rgba(255,255,255,0.3);
    
    .search-location {
        padding-top: 0;
        padding-bottom: 0;
        max-width: 100%;
        margin: auto;
    }
    @media (max-width: ${breakpointNotDesktop}) {
        border: none;
        padding: 0;
    }
`;

const SubNav = styled.nav`
    padding: 1.5rem 0;
    min-height: 8rem;
    display: flex;
    align-items: center;
    
    @media (max-width: ${breakpointNotDesktop}) {
        padding:  0  0;
        .sub-navbar__name {
            padding: 1.5rem 0;
        }
        .open-search-form  {
            .sub-navbar__right  {
                display: block!important;
                margin-left: 0!important;
            }
            .sub-navbar__left,.icon-search.d-md-none {
                display: none!important
            }
        }
       
    }
   
`;

const SearchBar = (props: any) => {
    let [openSearch, setOpenSearch] = React.useState(false)
    const handleClick = (event: any) => {
        alert('')
        setOpenSearch(true);
    }
  return (
    <>
        <SubNav className='sub-navbar bg-blue' >
            <div className={openSearch ? 'open-search-form container' : ' ' + ' container'}>
                <div className='d-flex justify-content-between justify-md-content-start  align-items-center'>
                    <div className='sub-navbar__left d-lg-flex align-items-center pr-5'>
                        <div className='sub-navbar__name d-flex justify-content-between align-items-center'>
                            <SubNavTitle>Locations</SubNavTitle>
                        </div>
                    </div>
                    <i className='icon-search d-block d-md-none' onClick={()=>  setOpenSearch(true)}></i>
                    <div className='sub-navbar__right ms-5 flex-grow-1 d-none d-md-block'>
                        <SearchLocation className='p-0 m-0'></SearchLocation>
                    </div>
                </div>
            </div>   
        </SubNav>

    </>
  )
}
export default SearchBar
